import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ['focused'];

  connect() {
    this.highlight();
    window.addEventListener('hashchange', ()=> this.highlight(), false)
  }

  highlight() {
    const fragID = this.hashLocation();
    if (fragID) {
     this.removeHighlights(this.element);
     this.highlightElement(`#${fragID}`);
    }
  }

  hashLocation() {
    return window.location.hash.slice(1)
  }

  removeHighlights(container) {
    container.querySelectorAll(`.${this.focusedClass}`).forEach(element => {
      element.classList.remove(this.focusedClass);
    });
  }

  highlightElement(selector) {
    const target = this.element.querySelector(selector);
    if (target) {
      target.classList.add(this.focusedClass);
    }
  }

  disconnect() {
    window.removeEventListener('hashchange', () => this.highlight(), false);
  }
}
