import {Controller} from "@hotwired/stimulus"
import EasyMDE from 'easymde/dist/easymde.min';

export default class extends Controller {
  initialize() {
    this.editorOptions = {
      autoDownloadFontAwesome: false,
      spellChecker: false,
      nativeSpellcheck: true,
      inputStyle: 'contenteditable',
      placeholder: this.element.dataset.placeholder,
      minHeight: this.element.dataset.minHeight || '300px',
      toolbar: [
        "bold",
        "italic",
        "strikethrough",
        "|",
        "heading-1",
        "heading-2",
        "heading-3",
        "|",
        "code",
        "quote",
        "unordered-list",
        "ordered-list",
        "clean-block",
        "|",
        "link",
        "image",
        "table",
        "|",
        "preview",
        "side-by-side",
        "fullscreen",
        "|",
        "guide",
        {
          name: "disable-mde",
          action: (editor) => {
            editor.toTextArea();
            editor = null;
          },
          className: "fa fa-power-off",
          title: "Disable Editor",
        },
      ]
    }
  }

  connect() {
    if (localStorage.getItem('useMarkdownEditor') !== 'false') {
      this.easyMDE = new EasyMDE({
        element: this.element,
        ...this.editorOptions
      });

      const editor = this.element.parentElement.querySelector('.EasyMDEContainer')
      editor.setAttribute('data-turbo-temporary', '')
    }
  }

}
