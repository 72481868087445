import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['previewButton']
  static classes = ['hidden'];

  connect() {
    this.previewButtonTarget.classList.add(this.hiddenClass);
  }

  render() {
    this.previewButtonTarget.click();
  }
}
