// Entry point for the build script in your package.json
import 'bootstrap/dist/js/bootstrap.bundle.min'
import "@hotwired/turbo-rails"
import "./controllers"

import "./local-time";

// use to disable turbo
Turbo.session.drive = localStorage.getItem('useTurbo') !== 'false';

// hack to view pages with broken/absent turbo response instead of "content missing"
document.addEventListener("turbo:frame-missing", event => {
  event.preventDefault()
  event.detail.visit(event.detail.response)
})
