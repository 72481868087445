import LocalTime from "local-time";

LocalTime.config.i18n["de"] = {
  date: {
    dayNames: [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ],
    abbrDayNames: [
      "So",
      "Mo",
      "Di",
      "Mi",
      "Do",
      "Fr",
      "Sa",
    ],
    monthNames: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    abbrMonthNames: [
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Okt",
      "Nov",
      "Dez",
    ],
    yesterday: "gestern",
    today: "heute",
    tomorrow: "morgen",
    on: "{date}",
    formats: {
      default: "%e. %B %Y",
      thisYear: "%e. %B",
    }
  },
  time: {
    am: "vormittags",
    pm: "nachmittags",
    singular: "{time}",
    singularAn: "{time}",
    elapsed: "vor {time}",
    second: "einer Sekunde",
    seconds: "Sekunden",
    minute: "einer Minute",
    minutes: "Minuten",
    hour: "einer Stunde",
    hours: "Stunden",
    formats: {
      default: "%H:%M Uhr"
    }
  },
  datetime: {
    at: "{date} um {time}",
    formats: {
      default: "%e %B %Y um %H:%M Uhr"
    }
  }
}

LocalTime.config.locale = "de";
LocalTime.start();
